import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/application-lifecycle.scss"
import { GiCycle } from "react-icons/gi"
import ButtonB from "../components/ButtonB"

//Images
import Divider from "../images/Blue-line.png"
import { SiHomeassistant } from "react-icons/si"
import { ImCogs } from "react-icons/im"
import { GoDashboard } from "react-icons/go"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import MapImg from "../images/map.png"
import LogoCarousel from "../components/LogoCarousel"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import TextAccordion from "../components/TextAccordion/TextAccordion"
import Diagram from "../components/Diagram"
import GartnerDiagram from "../components/GartnerDiagram"

import Cisco from "../images/cisco.png"

//data for accordion

//Cloud Native
const cloudPanels = [
  {
    icon: "",
    title: "Summary",
    content:
      "Cloud native computing is an approach in software development that typically means using open source  software stack to be containerised, where each part of the app is packaged in its own container, dynamically orchestrated so each part is actively scheduled and managed to optimise resource utilisation, and microservices-oriented to increase the overall agility and maintainability of applications. Cloud-native application development typically includes DevOps, agile methodology, microservices, cloud platforms, containers like Kubernetes and Docker, and continuous delivery. In short, every new and modern method of application deployment is implemented and is best leveraged in a PaaS environment.",
  },
  {
    title: "Value to Our Customers",
    content:
      "For cloud-native applications, the big difference then is really how the application is built, delivered, and operated. Taking advantage of cloud services means using agile and scalable components like containers to deliver discrete and reusable features that integrate in well-described ways, even across technology boundaries like multicloud, which allows delivery teams to rapidly iterate using repeatable automation and orchestration. Cloud native applications realise a faster release pace, superior customer experiences, ease of management, reduced cost through containerisation and cloud standards, along with better reliability and mitigation of vendor lock-in.",
  },
  {
    title: "Use Case",
    content:
      "Metsi’s focus on cloud native computing includes certified Kubernetes engineering, as well as DevOps, agile methodology, microservices, cloud platforms, and continuous delivery. For example, our multicloud serverless engineering enables you to publish and trigger functions and/ or Kubernetes workloads from other clouds or your legacy data centre applications by sharing events from one architecture to the other.",
  },
]

//API Driven Innovation
const apiPanels = [
  {
    icon: "",
    title: "Summary",
    content:
      "APIs represent more than technical conduits for sharing data. In today’s increasingly interconnected world, application programming interfaces (APIs) are becoming the digital reflection of an organisation. Well-designed APIs provide organisations with a critical link to data and services that enable rapid innovation, open up markets for new goods and services, and serve as the basis for future partnerships. Given the increasing importance of APIs, organisations need to understand the API lifecycle of its own technologies and also those of its partners, from inception to retirement, in much the same way a company oversees development and ongoing support for any digital service.",
  },
  {
    title: "Value to Our Customers",
    content:
      "The IT automation, integration, and orchestration that Metsi delivers is made possible through its expertise with API driven innovation. We enable our customers to integrate applications and data faster through connecting, integrating, and managing APIs. Metsi’s ability to easily stitch together different apps and features is essential in our cloud-based, interconnected world in which few businesses work in isolation, and users demand rich experiences across all platforms and devices.",
  },
  {
    title: "Use Case",
    content:
      "Metsi focuses on API connectivity, API integrations, and API management. An example of API connectivity is enabling clients to call APIs from legacy applications to enhance them with the power of cloud native functions. API integrations involve integrating enterprise-wide APIs to build enterprise-wide applications by stitching together a variety of APIs, thereby improving connectivity between data and systems. API management encompasses centralising and overseeing internal and public APIs in a secure and scalable environment, to create and enforce levels of usage, access, compliance, and security.",
  },
]

// const fullstackPanels = [
//   {
//     icon: "",
//     title: "Summary",
//     content:
//       "We build custom IaaS, PaaS, and DRaaS (Disaster Recovery-as-a-Service) solutions for our customers and partners. Because we are highly focused on IT automation, orchestration, and integration across the IT stack, we are able to leverage the best of breed in closed and open-source technologies to truly deliver the ever-elusive “single pane of glass” XaaS experience. We cover all aspects of the IaaS and PaaS journey to multicloud maturity: readiness assessments, a crawl-walk-run approach to XaaS delivery, and formal release engineering and application lifecycle for ongoing managed services.",
//   },
//   {
//     title: "Value to Our Customers",
//     content:
//       "Many customers start with IaaS, which helps abstract their applications from the underlying hardware, and then start their PaaS journey to add an extra layer of abstraction to the underlying OS so they can focus entirely on the business logic of their applications. Wherever you are in your XaaS journey, Metsi can help you take the next step toward future transformation. Our streamlined approach to PaaS maturity uses data-driven insights to drive continuous improvement, automates platform and infrastructure optimisation, and builds security and compliance into the service lifecycle.",
//   },
//   {
//     title: "Use Case",
//     content:
//       "Metsi has proven its XaaS capabilities at scale by designing, delivering, and productizing a custom PaaS solution that modernises US defense systems and allows those US defense systems to achieve 600% faster application deployment times through an end-to-end multi domain IT stack with Cisco. This PaaS solution delivers a custom Metsi analytics dashboard, full stack visibility, automated application performance, and end user provisioning and consumption on a secure, scalable, and fault tolerant abstracted architecture. ",
//   },
// ]

const ApplicationLifecycle = () => {
  //gatsby-image query
  const applicationData = useStaticQuery(graphql`
    query applicationImages {
      hero: file(relativePath: { eq: "lake-mountains-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      canopy: file(relativePath: { eq: "canopy.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sky: file(relativePath: { eq: "blue-sky.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Full Stack Engineering"
        description="Metsi offers world-class enterprise software development, application modernisation, DevOps and full stack visibility. Services include API interoperability and cloud native computing."
        keywords={[
          "api interoperability in cloud computing",
          "cloud infrastructure engineer professional services",
          "devops professional services",
          "enterprise infrastructure as a service",
          "enterprise it infrastructure design",
        ]}
        lang="en-gb"
      />
      {/********Hero Section *********/}
      <BackgroundImage
        fluid={applicationData.hero.childImageSharp.fluid}
        className="application-hero"
        style={{
          width: "100vw",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <GiCycle className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1 style={{ color: "#103144" }}>Full Stack Engineering</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p style={{ color: "#626366" }}>
                      Metsi has a rich history of developing software that is
                      effective, efficient and available to solve process
                      deficiencies, architectural sprawls, and rising support
                      costs. We have successfully built enterprise software,
                      protocols and migration tools for multiple industry
                      leading OEMs.
                    </p>
                    <p>
                      Today, we offer world-class enterprise software
                      development as well as application modernisation, DevOps
                      and full stack observability.
                    </p>
                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Full Stack Engineering</h2>
          <h3>
            Improved Collaboration, Faster Software Delivery, Minimal Rework
          </h3>
          <p>
            Metsi’s Application Management Services (AMS) provide both a
            reactive and proactive approach to your applications. Reactively, we
            give you a strategic view of your IT systems for incident
            management, service response times and more. Proactively, we deliver
            solutions for release management, change management, and operational
            management.
          </p>
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3">
            <a href="#api-interoperability">
              <div className="droplet-blue">
                <GoDashboard className="icon" />
              </div>
            </a>
            <a href="#api-interoperability">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                API Interoperability
              </h2>
            </a>
          </div>

          <div className="inner-container-3">
            <a href="#cloud_native">
              <div className="droplet-blue">
                <SiHomeassistant className="icon" />
              </div>
            </a>
            <a href="#cloud_native">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Cloud Native
              </h2>
            </a>
          </div>

          {/* <div className="inner-container-3">
            <a href="#fullstack">
              <div className="droplet-blue">
                <ImCogs className="icon" />
              </div>
            </a>
            <a href="#fullstack">
              <h2 style={{ color: "#000" }}>XaaS</h2>
            </a>
          </div> */}
        </div>
      </div>

      {/*********** API Interoperability *************/}
      <BackgroundImage
        fluid={applicationData.sky.childImageSharp.fluid}
        className="section"
        id="api-interoperability"
        style={{
          width: "100vw",
          minHeight: "70vh",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Full Stack Engineering</h2>
              <h3>API Interoperability</h3>
            </div>
          </div>
          <TextAccordion panels={apiPanels} />
        </div>
      </BackgroundImage>

      {/********* Cloud Native ***********/}
      <BackgroundImage
        fluid={applicationData.canopy.childImageSharp.fluid}
        className="section"
        id="cloud_native"
        style={{
          width: "100vw",
          minHeight: "80vh",
          backgroundPosition: "top",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Full Stack Engineering</h2>
              <h3>Cloud Native</h3>
            </div>
          </div>
          <TextAccordion panels={cloudPanels} />
        </div>
      </BackgroundImage>

      {/*********** XaaS *************/}
      {/* <div className="section" id="fullstack">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Application Lifecycle</h2>
              <h3>XaaS - Anything-as-a-Service</h3>
            </div>
          </div>
          <TextAccordion panels={fullstackPanels} />
        </div>
      </div> */}

      {/*************** Diagram  ****************/}
      {/* <Diagram /> */}
      {/*************** SVG Diagram  ****************/}

      {/* <div className="section">
        <GartnerDiagram
          style={
            {
              width: "100%" 
            }
          }
        />
      </div> */}

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ApplicationLifecycle
